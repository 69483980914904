import PropTypes from 'prop-types';

const QuizGameProgressBar = ({ current, total }) => {
    const progressPercentage = (current / total) * 100;

    return (
        <div className="flex items-center">
            <div className="w-full bg-gray-300 rounded-full h-3 mx-4 dark:bg-gray-700">
                <div
                    className="bg-quiz_blue h-3 rounded-full"
                    style={{ width: `${progressPercentage}%` }}
                ></div>
            </div>
            <span className="text-2xl text-bold mx-4 my-4 font-sans text-gray-700 font-bold">
                {`${current}/${total}`}
            </span>
        </div>
    );
};

QuizGameProgressBar.propTypes = {
    current: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
};

export default QuizGameProgressBar;

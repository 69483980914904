import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;

const SubcategoryButtons = () => {
  const [subcategories, setSubcategories] = useState([]);
  const { categoryName } = useParams();

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/subcategories/${categoryName}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSubcategories(data.results);
      } catch (error) {
        console.error("Fetching subcategories failed: ", error);
      }
    };

    if (categoryName) {
      fetchSubcategories();
    }
  }, [categoryName]);

  if (subcategories.length === 0) {
    return null; // Don't render the component if there are no subcategories.
  }

  // Function to render subcategory buttons with 4-3-4-3 pattern
  const renderButtonsWithPattern = () => {
    let buttons = [];
    let rowLimit = 4; // Start with a limit of 4 buttons in the row
    let currentCount = 0;

    subcategories.forEach((subcategory, index) => {
      // If we hit the row limit, reset the count and toggle the row limit between 4 and 3
      if (currentCount === rowLimit) {
        buttons.push(<div key={`break-${index}`} className="w-full"></div>); // This creates a break to the new line
        rowLimit = rowLimit === 4 ? 3 : 4; // Toggle between 4 and 3 for the new row
        currentCount = 0; // Reset count for the new row
      }

      buttons.push(
        <Link key={subcategory.id}
              to={`/kategorie/${subcategory.name}`}
              className="bg-quiz_green hover:bg-green-500 text-black font-bold py-2 px-4 rounded-lg">
          {subcategory.name}
        </Link>
      );
      currentCount++; // Increment the count for the current row
    });

    return buttons;
  };

  return (
    <div className="flex flex-wrap justify-center gap-4 m-3">
      {renderButtonsWithPattern()}
    </div>
  );
};

export default SubcategoryButtons;

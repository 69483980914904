import React, { useState, useEffect } from 'react';
import Downshift from 'downshift';
import { useNavigate } from 'react-router-dom';

const Search = ({ setIsSearching }) => {
    const [quizzes, setQuizzes] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${apiUrl}/api/quiz`)
            .then(response => response.json())
            .then(data => {
                setQuizzes(data.results);
            })
            .catch(error => console.error('Error fetching quizzes:', error));
    }, [apiUrl]);

    useEffect(() => {
        const hasResults = quizzes.filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase())).length > 0;
        setIsSearching(inputValue.trim().length > 0 && hasResults);
    }, [inputValue, quizzes, setIsSearching]);

    return (
        <Downshift
            itemToString={item => (item ? item.name : '')}
            inputValue={inputValue}
            onInputValueChange={value => setInputValue(value)}
            onChange={item => {
                if (item) {
                    navigate(`/quiz/${item.slug}`);
                    setIsSearching(false); // Hide other components when an item is selected
                }
            }}
        >
            {({
                getInputProps,
                getItemProps,
                getMenuProps,
                isOpen,
                highlightedIndex,
                selectedItem,
                clearSelection
            }) => (
                <div
                    className={`relative mb-8 w-full rounded-lg pb-2 ${isOpen && inputValue ? 'bg-gray-300' : 'bg-gray-300'}`}>
                    <div
                        className="text-sky-50 text-center text-3xl font-bold font-sans leading-10 whitespace-nowrap justify-center items-center bg-quiz_blue self-stretch pt-3.5 pb-2 px-5 rounded-t-lg">
                        Znajdź quiz
                    </div>
                    <div className="p-2">
                        <input
                            {...getInputProps({
                                placeholder: "Zacznij wpisywać...",
                                className: "form-input w-full pl-2.5 pr-5 py-4 border-2 border-gray-300 font-sans text-neutral-500 text-2xl not-italic font-medium leading-8 border bg-white text-lg rounded-lg focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50",
                                onBlur: () => {
                                    clearSelection();
                                    setIsSearching(false);
                                }
                            })}
                        />
                        <ul {...getMenuProps()}
                            className="absolute w-full left-0 z-10 font-sans bg-gray-300 shadow-md rounded-lg p-2">
                            {isOpen && inputValue.trim().length > 0 && quizzes
                                .filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()))
                                .slice(0, 7) // Limit results to 7
                                .map((item, index) => (
                                    <li
                                        {...getItemProps({
                                            key: item.id,
                                            index,
                                            item,
                                            className: `cursor-pointer p-2 ${highlightedIndex === index ? 'bg-blue-100' : 'bg-gray-300'} ${selectedItem === item ? 'font-bold' : 'font-bold'}`
                                        })}
                                        onClick={() => {
                                            navigate(`/quiz/${item.slug}`);
                                            clearSelection();
                                            setIsSearching(false); // Reset when an item is selected
                                        }}
                                    >
                                        {item.name}
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            )}
        </Downshift>
    );
};

export default Search;

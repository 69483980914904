import React from 'react';
import { Link } from 'react-router-dom';

const QuizView = ({ id, name, number_of_questions, time, required_score_to_pass, difficulty, category_tree, imgSrc, imgSrc_small_url, imgSrc_medium_url, imgSrc_large_url, is_featured }) => {
  const categoryPath = category_tree.join(' > ');
  const mediaBaseUrl = process.env.REACT_APP_API_URL;

  // Function to dynamically choose image source based on screen width
  const chooseImageSrc = () => {
    const screenWidth = window.innerWidth;
    let selectedSrc = imgSrc; // Default to original imgSrc if specific sizes aren't available

    if (screenWidth < 640) {
      selectedSrc = imgSrc_small_url || imgSrc;
    } else if (screenWidth >= 640 && screenWidth < 1024) {
      selectedSrc = imgSrc_medium_url || imgSrc;
    } else {
      selectedSrc = imgSrc_large_url || imgSrc;
    }

    // Ensure we return the full URL if a relative path is provided
    return selectedSrc.startsWith('http') ? selectedSrc : `${mediaBaseUrl}${selectedSrc}`;
  };

  return (
    <div className={`bg-white w-full rounded-lg shadow-md mb-3 overflow-hidden ${is_featured ? 'border-2 border-blue-500' : ''}`}>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-2/5 p-4 flex justify-center md:justify-start">
            <img
              loading="lazy"
              src={chooseImageSrc()} // Use the dynamically chosen image source
              alt={name}
              className="h-64 w-full object-cover rounded-md"
            />
        </div>
        <div className="md:w-3/5 p-4">
          <div className="flex flex-col justify-between h-full">
            <div>
              <span className="text-lg font-semibold text-gray-500">{categoryPath}</span>
                <h3 className="text-3xl font-bold text-blue-500 mt-1">{name}</h3>
              <p className="text-gray-700 mt-2">
                Pytania: {number_of_questions}, Czas: {time} min, Wymagany wynik: {required_score_to_pass}%, Trudność: {difficulty}
              </p>
            </div>
            {/* Additional content can go here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizView;

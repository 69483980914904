import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const SocialLinks = () => {
  return (
    <div className="mb-8 w-full bg-gray-300 font-sans rounded-lg">
      <div className="text-sky-50 font-sans text-center text-3xl font-bold leading-10 whitespace-nowrap justify-center items-center bg-quiz_blue self-stretch pt-3.5 pb-2 px-5 rounded-lg max-md:max-w-full">
        Zaobserwuj nas!
      </div>
      <div className="pl-2 pb-2 pr-2 rounded-lg">
        <div className="mt-2 flex flex-col items-center">
          <a href="https://www.facebook.com/Obudpl/" target="_blank" rel="noopener noreferrer" className="w-full">
            <div className="flex items-center text-neutral-500 text-2xl font-medium rounded-lg border bg-white w-full pl-2.5 pr-5 py-4 border-solid border-blue-400 hover:border-blue-500 hover:bg-blue-50 hover:text-blue-600">
              <FontAwesomeIcon icon={faFacebook} size="2x" className="text-blue-500 mr-2" />
              <span className="align-middle font-sans">/obudpl</span>
            </div>
          </a>
          <a href="https://www.instagram.com/obudpl/" target="_blank" rel="noopener noreferrer" className="w-full mt-2">
            <div className="flex items-center text-neutral-500 text-2xl font-medium rounded-lg border bg-white w-full pl-2.5 pr-5 py-4 border-solid border-blue-400 hover:border-blue-500 hover:bg-blue-50 hover:text-blue-600">
              <FontAwesomeIcon icon={faInstagram} size="2x" className="text-black mr-2" />
              <span className="align-middle font-sans">/obudpl</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialLinks;

import React, { useState } from 'react';
import PopularCategories from './PopularCategories';
import SocialLinks from './SocialLinks';
import PopularQuizes from './PopularQuizes';
import Search from './Search';

const Sidebar = () => {
  const [isSearching, setIsSearching] = useState(false);

  return (
    <div className="w-full rounded-lg ">
      <PopularCategories />
      <Search setIsSearching={setIsSearching} />
      <div className={isSearching ? "invisible" : ""}>
        <SocialLinks />
      </div>
      <PopularQuizes />
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;

const PopularQuizzes = () => {
  const [quizzes, setQuizzes] = useState([]);

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/quiz/popular?limit=4`);
        if (response.ok) {
          const data = await response.json();
          setQuizzes(data.results);
        } else {
          console.error('Failed to fetch quizzes');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchQuizzes();
  }, []);

  // Function to choose the appropriate image source based on the screen width
  const getImageSrc = (quiz) => {
    const screenWidth = window.innerWidth;
    let selectedSrc = quiz.imgSrc; // Default image source

    if (screenWidth < 640) {
      selectedSrc = quiz.imgSrc_small_url || quiz.imgSrc;
    } else if (screenWidth >= 640 && screenWidth < 1024) {
      selectedSrc = quiz.imgSrc_medium_url || quiz.imgSrc;
    } else {
      selectedSrc = quiz.imgSrc_large_url || quiz.imgSrc;
    }

    // Prepend the base URL if the selected image source is a relative path
    return selectedSrc.startsWith('http') ? selectedSrc : `${apiUrl}${selectedSrc}`;
  };

  return (
    <div className="mb-8 w-full bg-gray-300 rounded-lg ">
      <div className="text-sky-50 text-center text-3xl font-sans font-bold leading-10 whitespace-nowrap justify-center items-center bg-quiz_blue self-stretch pt-3.5 pb-3 rounded-lg max-md:max-w-full">
        Najczęściej wybierane
      </div>
      <div className="bg-zinc-300 w-full p-2 flex flex-col rounded-lg">
        {quizzes.map((quiz) => (
          <div key={quiz.id} className="bg-white mb-5 rounded-lg self-end w-full overflow-hidden">
            <div className="flex flex-row items-start p-2">
              <div className="flex-none w-48 h-32 relative overflow-hidden rounded-tl-lg rounded-lg">
                <img
                  loading="lazy"
                  src={getImageSrc(quiz)} // Dynamically choose the image source
                  alt={quiz.name}
                  className="absolute inset-0 w-full h-full object-cover rounded-tl-lg rounded-bl-lg"
                />
              </div>
              <div className="flex-grow px-3.5 pt-1 pb-2 font-sans">
                <Link to={`/quiz/${quiz.slug}`} className="text-blue-400 text-xl font-bold mt-1">
                  {quiz.name}
                </Link>
                <div className="text-neutral-500 text-sm font-medium mt-1 font-sans">
                  Ilość pytań: {quiz.number_of_questions}, Czas: {quiz.time} min, Trudność: {quiz.difficulty}
                </div>
              </div>
            </div>
          </div>
        ))}
        <Link to="/quizzes/popular" className="text-white font-sans text-center text-lg not-italic font-medium leading-6 whitespace-nowrap justify-center items-stretch bg-quiz_green flex w-[135px] max-w-full flex-col px-1.5 py-1.5 rounded-md self-end max-md:pr-px">
          Zobacz więcej
        </Link>
      </div>
    </div>
  );
};

export default PopularQuizzes;

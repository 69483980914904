import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import QuizCard from './CategoryQuizCard'; // Ensure this import matches your component file name
import SubcategoryButtons from './SubcategoryButtons';
const apiUrl = process.env.REACT_APP_API_URL;

const CategoryQuizzes = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [category, setCategory] = useState(null);
  const { categoryName } = useParams();

  useEffect(() => {
    const fetchQuizzesByCategory = async () => {
      try {
        // Correctly construct the URL with filter_type, filter_field, and filter_value
        const response = await fetch(`${apiUrl}/api/quiz/?filter_type=category&filter_field=name&filter_value=${encodeURIComponent(categoryName)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setQuizzes(data.results);
      } catch (error) {
        console.error("Fetching quizzes failed: ", error);
      }
    };


    const fetchSubcategories = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/subcategories/${encodeURIComponent(categoryName)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Sub Categories Data:', data); // Dodaj tę linię

        setSubcategories(data.results);
      } catch (error) {
        console.error("Fetching subcategories failed: ", error);
      }
    };

    const fetchCategory = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/category/${encodeURIComponent(categoryName)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Category Data:', data); // Dodaj tę linię

        setCategory(data);
      } catch (error) {
        console.error("Fetching category failed: ", error);
      }
    };

    if (categoryName) {
      fetchQuizzesByCategory();
      fetchSubcategories();
      fetchCategory();
    }
  }, [categoryName]);

  return (
    <div>
      <div className="text-sky-50 text-center text-3xl font-sans font-bold leading-10 whitespace-nowrap justify-center items-center bg-quiz_blue self-stretch pt-3.5 pb-2 px-5 rounded-lg max-md:max-w-full">
        {category ? `${category.parent_name ? category.parent_name + ' | ' : ''}${category.name}` : 'Loading category...'}
      </div>
      <SubcategoryButtons subcategories={subcategories} /> {/* Assuming SubcategoryButtons component takes subcategories as prop */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-4">
        {quizzes.map(quiz => (
          <div key={quiz.id} className="rounded-lg shadow-md h-auto bg-white">
            <QuizCard
              quiz={quiz}
              imgSrc={quiz.imgSrc}
              imgSrcSmall={quiz.imgSrc_small_url}
              imgSrcMedium={quiz.imgSrc_medium_url}
              imgSrcLarge={quiz.imgSrc_large_url}
            />
          </div>
        ))}
        {quizzes.length === 0 && <div className="text-center col-span-2">No quizzes available for this category.</div>}
      </div>
    </div>
  );
};

export default CategoryQuizzes;

import React from 'react';
import PropTypes from 'prop-types';

const AnswerButton = ({ answer, isSelected, onClick, isSubmitted, isCorrect }) => {
    const getButtonClass = () => {
        // Base classes for all states
        let baseClasses = 'py-3 px-6 text-lg rounded focus:outline-none font-bold border-l-4 border-blue-300 transition-colors';

        if (isSubmitted) {
            // Change the background color depending on whether the answer is correct or not
            return `${baseClasses} ${isCorrect ? 'bg-green-500 border-r-green-700' : 'bg-red-500 border-r-red-700'}`;
        } else {
            // Change the background color depending on whether the answer is selected or not
            return `${baseClasses} ${isSelected ? 'bg-gray-300 border-r-gray-500' : 'bg-white border-r-blue-500'}`;
        }
    };

    return (
        <button
            className={`${getButtonClass()} ${isSubmitted && 'cursor-not-allowed'}`}
            onClick={onClick}
            disabled={isSubmitted}
        >
            {answer.text}
        </button>
    );
};


AnswerButton.propTypes = {
    answer: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    isSubmitted: PropTypes.bool.isRequired,
    isCorrect: PropTypes.bool.isRequired
};

export default AnswerButton;

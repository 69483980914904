import React from 'react';
import { Link } from 'react-router-dom';
import QuizView from './QuizView';

const FeaturedQuiz = ({ quiz }) => {
  return (
    <div className="bg-gray-300 font-sans rounded-lg mx-5">
      <div className="text-sky-50 text-center text-3xl font-bold font-sans leading-10 whitespace-nowrap justify-center items-center bg-quiz_blue self-stretch pt-3.5 pb-2 px-5 rounded-lg max-md:max-w-full mx-[-5]">
        QUIZ TYGODNIA
      </div>
      <div className="m-3 pb-4">
        {/* Wrap the QuizView in a Link to make it clickable */}
        {/* Assuming `quiz.id` is the unique identifier for the quiz */}
        <Link to={`/quiz/${quiz.slug}`} className="block">
          <QuizView {...quiz} />
        </Link>
      </div>
    </div>
  );
};

export default FeaturedQuiz;

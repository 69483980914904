import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL; // Ensure this is set in your .env file

const CategoriesCards = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/categories/?parent=null`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // Append the base URL to the image paths
        const updatedData = data.results.map(category => ({
          ...category,
          bgImage_small_url: apiUrl + category.bgImage_small_url,
          bgImage_medium_url: apiUrl + category.bgImage_medium_url,
          bgImage_large_url: apiUrl + category.bgImage_large_url,
        }));
        setCategories(updatedData);
      } catch (error) {
        console.error("Fetching categories failed: ", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="grid grid-cols-2 gap-4">
      {categories.map(category => (
          <Link
              key={category.name} // Add a unique key prop here
              to={`/kategorie/${category.name}`}
              className="relative rounded-lg shadow-md h-64"
          >
            <img
                src={category.bgImage_large_url}
                alt={category.name}
                className="object-cover w-full h-full rounded-lg"
            />
            <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center rounded-lg">
              <span className="text-white text-xl font-bold">{category.name}</span>
            </div>
          </Link>
      ))}
    </div>
  );
};

export default CategoriesCards;

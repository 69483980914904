import React from "react";
import { Link } from "react-router-dom";

const Navibar = () => {
  return (
    <div className="w-full">
      <nav className="border-gray-200 px-2 sm:px-4 py-2.5 rounded bg-white">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          {/* Link i nazwa aplikacji QUIZy */}
          <Link to="/" className="flex items-center">
            <span className="self-center text-7xl font-roboto font-extrabold whitespace-nowrap text-blue-400">QUIZy</span>
            <span className="text-lime-500 text-7xl font-roboto font-extrabold">.</span>
          </Link>

          {/* Linki nawigacyjne */}
          <div className="hidden md:flex items-center justify-center w-full md:w-auto md:order-1">
            <ul className="flex-col mt-4 md:flex-row flex md:space-x-8 mx-4 md:text-sm md:font-medium text-center">
              <li>
                <Link to="/najlepsze"
                      className="text-gray-700 text-3xl font-sans font-extrabold hover:bg-blue-700 md:hover:bg-transparent md:border-0 block px-3 py-2 rounded md:hover:text-blue-700 md:p-0"
                      aria-current="page">Najlepsze</Link>
              </li>
              <li>
                <Link to="/wylosuj-quiz"
                      className="text-gray-700 text-3xl font-sans font-extrabold hover:bg-blue-700 md:hover:bg-transparent md:border-0 block px-3 py-2 rounded md:hover:text-blue-700 md:p-0">Wylosuj quiz</Link>
              </li>
              <li>
                <Link to="/kategorie"
                      className="text-gray-700 text-3xl font-sans font-extrabold hover:bg-blue-700 md:hover:bg-transparent md:border-0 block px-3 py-2 rounded md:hover:text-blue-700 md:p-0">Kategorie</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navibar;

import React from 'react';
import PropTypes from 'prop-types';

const SubmitButton = ({ onClick, disabled }) => {
    return (
        <button
            className="py-3 px-6 text-lg rounded-lg bg-green-400 hover:bg-green-500 text-white font-bold focus:outline-none focus:shadow-outline"
            onClick={onClick}
            disabled={disabled}
        >
            Zatwierdź odpowiedź
        </button>
    );
};

SubmitButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
};

export default SubmitButton;

import React from 'react';
import PropTypes from 'prop-types';

const QuizResults = ({ score, totalQuestions, averageTime, fastestTime, longestTime, gameHash, quizSlug }) => {
    const formatNumber = (num) => num !== undefined ? num.toFixed(2) : 'N/A';
    const quizUrl = `https://quiz.obud.pl/quiz/${quizSlug}`; // Zastąp URL swoim rzeczywistym URL do quizu

    const shareOnTwitter = () => {
        const text = `Sprawdź moje wyniki w Quizie! Zdobyłem ${formatNumber(score)}%, średni czas odpowiedzi ${formatNumber(averageTime)} sekund. Zagraj tutaj: ${quizUrl} Moje ID gry to: ${gameHash}`;
        const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
        window.open(url, '_blank');
    };

    return (
        <div className="mb-8 w-full bg-gray-300 rounded-lg ">

            <div className="text-sky-50 text-center text-3xl font-sans font-bold leading-10 whitespace-nowrap justify-center items-center bg-quiz_blue self-stretch pt-3.5 pb-2 px-5 rounded-lg max-md:max-w-full">
                Wyniki Quizu
            </div>
                <div className="max-w-4xl mx-auto my-8 p-6 bg-gray-300 font-sans rounded-lg shadow-md text-center">
                    <p className="text-lg">Twój wynik: {formatNumber(score)}%</p>
                    <p className="text-lg">Średni czas na pytanie: {formatNumber(averageTime)} sekund</p>
                    <p className="text-lg">Najszybszy czas odpowiedzi: {formatNumber(fastestTime)} sekund</p>
                    <p className="text-lg">Najdłuższy czas odpowiedzi: {formatNumber(longestTime)} sekund</p>
                    <p className="text-lg">Mój unikalny id gry: {gameHash}</p>
                    <p className="text-lg">URL do Quizu: {quizUrl}</p>
                    <button className="mt-4 px-4 py-2 bg-quiz_blue text-white rounded hover:bg-blue-600"
                            onClick={shareOnTwitter}>
                        Udostępnij na Twitterze
                    </button>
                </div>
            </div>
                );
                };

                QuizResults.propTypes = {
                score: PropTypes.number,
                totalQuestions: PropTypes.number,
                averageTime: PropTypes.number,
                fastestTime: PropTypes.number,
                longestTime: PropTypes.number,
                gameHash: PropTypes.string // Dodajemy prop gameHash
            };

                export default QuizResults;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navibar from "./components/Navbar/navbar";
import LeftPane from "./components/LeftPane/LeftPane";
import QuizInfoPage from "./components/QuizGameComponents/QuizInfoPage";
import QuizGame from "./components/QuizGameComponents/QuizGame";
import Sidebar from "./components/SideBar/Sidebar";
import CategoriesCards from "./components/Categories/CategoriesCards";
import CategoryQuizes from "./components/Categories/CategoryQuizes";
import Tags from "./components/Tags";
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';


function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="flex flex-col min-h-screen bg-amber-10">
        <div className="flex flex-grow">
          <div className="w-1/24"></div> {/* Zmniejszony lewy margines */}
          <div className="w-full lg:w-22/24"> {/* Zaktualizuj tę wartość, aby zrekompensować zmniejszone marginesy */}
            <Navibar />
            <div className="flex flex-col lg:flex-row">
              <div className="w-full lg:w-3/5 px-2 py-8">
                <Routes>
                  <Route path="/kategorie" element={<CategoriesCards />} />
                  <Route path="/kategorie/:categoryName" element={<CategoryQuizes />} />
                  <Route path="/quiz/:quizName" element={<QuizInfoPage />} />
                  <Route path="/quiz/start/:quizId" element={<QuizGame />} />
                  <Route path="/" element={<LeftPane />} />
                  <Route path="/tag/:tagName" element={<LeftPane />} />
                </Routes>
              </div>
              <div className="px-2 py-8 w-full lg:w-2/5">
                <Sidebar />
              </div>
            </div>
          </div>
          <div className="w-1/24"></div> {/* Zmniejszony prawy margines */}
        </div>
        <Tags />
        <Footer />
      </div>
    </Router>
  );
}


export default App;

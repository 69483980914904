import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import QuizResults from './QuizResults';
import QuizQuestion from './QuizQuestion';
import useFetchQuestions from '../../hooks/useFetchQuestions';
import QuizGameProgressBar from './QuizGameProgressBar';
import QuizHeader from './QuizHeader';
import SubmitButton from './SubmitButton'; // Import the SubmitButton component
import RelatedQuizes from './RelatedQuizes'; // Make sure this import path is correct

const apiUrl = process.env.REACT_APP_API_URL;

const QuizGame = () => {
    const { quizId } = useParams();
    const { questions, isLoading, error } = useFetchQuestions(quizId);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [answerSubmitted, setAnswerSubmitted] = useState(false);
    const [correctAnswersMap, setCorrectAnswersMap] = useState({});
    const location = useLocation(); // Use useLocation to access the passed state
    const quizName = location.state?.quizName; // Access the quizName passed via state
    const quizSlug = location.state?.quizSlug; // Access the quizSlug passed via state
    const [gameState, setGameState] = useState({
        submitted: false,
        quizSlug: quizSlug,
        gameHash: '',
        showResults: false,
        results: [],
        finalResults: {
            score: { correct: 0, incorrect: 0 },
            averageTime: 0,
            fastestTime: 0,
            longestTime: 0
        }
    });
    const [relatedQuizzes, setRelatedQuizzes] = useState([]);

    const isMounted = useRef(true);

    const fetchRelatedQuizzes = async (tags, currentQuizId) => {
        console.log("Current Quiz ID:", currentQuizId); // Log current quiz ID for debugging
        try {
            const tagParams = tags.map(tag => `tags=${tag}`).join('&');
            const url = `${apiUrl}/api/quiz/?${tagParams}`;
            const response = await axios.get(url);
            console.log("All quizzes (including current):", response.data); // Log all quizzes received
            // Ensure both IDs are compared as integers
            const filteredQuizzes = response.data.filter(quiz => quiz.id !== parseInt(currentQuizId));
            console.log("Filtered quizzes (excluding current):", filteredQuizzes); // Log filtered quizzes
            setRelatedQuizzes(filteredQuizzes);
        } catch (error) {
            console.error('Error fetching related quizzes:', error);
        }
    };





    useEffect(() => {
        const fetchQuizDetails = async () => {
            try {
                const { data } = await axios.get(`${apiUrl}/api/quiz/${quizId}`);
                if (isMounted.current) {
                    setGameState(prevState => ({ ...prevState, gameHash: data.game_hash }));
                    // Przekazujemy identyfikator bieżącego quizu do funkcji pobierającej podobne quizy
                    if (data.quiz.tags.length > 0) {
                        fetchRelatedQuizzes(data.quiz.tags, quizId);
                    }
                }
            } catch (error) {
                console.error('Error fetching quiz details:', error);
            }
        };

        fetchQuizDetails();
    }, [quizId]);  // Upewnij się, że quizId jest dodane do listy zależności useEffect



    useEffect(() => {
        setStartTime(new Date().toISOString());
    }, [currentQuestionIndex]);

    const toggleAnswer = (answerId) => {
        setSelectedAnswers(prev =>
            prev.includes(answerId) ? prev.filter(id => id !== answerId) : [...prev, answerId]
        );
    };

    const handleAnswerSubmit = async () => {
        setGameState(prevState => ({ ...prevState, submitted: true }));
        try {
            const payload = {
                game_hash: gameState.gameHash,
                answers: selectedAnswers,
                start_time: startTime,
                quiz_id: quizId,
            };

            const response = await axios.post(
                `${apiUrl}/api/game/question/submit/${questions[currentQuestionIndex].id}/`,
                payload
            );

            const { correct_answer_ids } = response.data;

            let newCorrectAnswersMap = {};
            questions[currentQuestionIndex].answers.forEach(answer => {
                newCorrectAnswersMap[answer.id] = correct_answer_ids.includes(answer.id);
            });

            setCorrectAnswersMap(newCorrectAnswersMap);

            setAnswerSubmitted(true);

            setTimeout(() => {
                setAnswerSubmitted(false);
                moveToNextQuestion();
            }, 2000); // Delay for 2 seconds

        } catch (error) {
            console.error('Error submitting answer:', error);
        }
    };

    const moveToNextQuestion = () => {
        if (currentQuestionIndex + 1 < questions.length) {
            setCurrentQuestionIndex(prev => prev + 1);
            setSelectedAnswers([]);
            setGameState(prevState => ({ ...prevState, submitted: false }));
            setCorrectAnswersMap({});
        } else {
            handleFinalSubmit();
        }
    };

    const handleFinalSubmit = async () => {
        try {
            const response = await axios.post(`${apiUrl}/api/game/quiz/submit/${quizId}/`, {
                game_hash: gameState.gameHash,
            });

            if (isMounted.current) {
                setGameState(prevState => ({
                    ...prevState,
                    showResults: true,
                    finalResults: {
                        score: response.data.score,
                        averageTime: response.data.average_time_per_question,
                        fastestTime: response.data.fastest_response_time,
                        longestTime: response.data.longest_response_time
                    }
                }));
            }
        } catch (error) {
            if (isMounted.current) {
                console.error('Error finalizing quiz:', error);
            }
        }
    };

    useEffect(() => {
        let timer;
        if (gameState.submitted && !answerSubmitted) {
            timer = setTimeout(() => {
                moveToNextQuestion();
            }, 2000);
        }

        return () => clearTimeout(timer);
    }, [gameState.submitted, currentQuestionIndex, answerSubmitted]);

    if (isLoading) {
        return <div className="text-center p-4">Loading...</div>;
    }

    if (error) {
        return <div className="text-center p-4 text-red-500">{error}</div>;
    }

    if (gameState.showResults) {
        return (
                <QuizResults
                    score={gameState.finalResults.score}
                    totalQuestions={questions.length}
                    averageTime={gameState.finalResults.averageTime}
                    fastestTime={gameState.finalResults.fastestTime}
                    longestTime={gameState.finalResults.longestTime}
                    gameHash={gameState.gameHash}
                    quizSlug={gameState.quizSlug} // Przekazanie quizSlug do komponentu QuizResults
                />

        );
    }

    const currentQuestion = questions[currentQuestionIndex];

    if (!currentQuestion) {
        return <div className="text-center p-4">No questions found</div>;
    }

    const isButtonDisabled = selectedAnswers.length === 0 || gameState.submitted;

    return (
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md">
            {quizName && <QuizHeader quizName={quizName} />}
            <div className="border-b-2 border-gray-200 mb-4">
                <QuizGameProgressBar current={currentQuestionIndex + 1} total={questions.length} />
            </div>
            <QuizQuestion
                question={currentQuestion}
                selectedAnswers={selectedAnswers}
                toggleAnswer={toggleAnswer}
                submitted={gameState.submitted}
                correctAnswersMap={correctAnswersMap}
            />
            <div className="flex justify-end items-center p-4">
                <div className="ml-4"></div>
                {/* Margin on the left of the button */}
                <SubmitButton onClick={handleAnswerSubmit} disabled={isButtonDisabled}/>
            </div>
            <RelatedQuizes quizzes={relatedQuizzes} />
        </div>
    );
};

export default QuizGame;

import React from 'react';
import { Link } from 'react-router-dom';
import QuizView from './QuizView';

const QuizList = ({ quizzes, heading, nextUrl, previousUrl, onPageChange }) => {
  return (
    <div className="bg-blue-300 rounded-lg font-sans mx-5">
      <div className="text-sky-50 text-center text-3xl font-bold font-sans leading-10 whitespace-nowrap justify-center items-center bg-quiz_green self-stretch pt-3.5 pb-2 px-5 rounded-lg max-md:max-w-full mx-[-5]">
        {heading}
      </div>
      <div className="m-3 pb-4">
        {quizzes.map((quiz) => (
          <Link to={`/quiz/${quiz.slug}`} key={quiz.slug} className="block mb-4">
            <QuizView {...quiz} />
          </Link>
        ))}
        <div className="pagination flex justify-center mx-4 font-bold font-sans">
          {previousUrl && (
            <button onClick={() => onPageChange('prev')} className="bg-quiz_blue text-white py-2 px-4 rounded-lg">
              Poprzednia
            </button>
          )}
          {nextUrl && (
            <button onClick={() => onPageChange('next')} className="bg-quiz_blue text-white py-2 px-4 rounded-lg">
              Następna
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizList;

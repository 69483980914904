import PropTypes from 'prop-types';

const QuizHeader = ({ quizName }) => {
    return (
        <div className="text-sky-50 text-center text-3xl font-sans font-bold leading-10 whitespace-nowrap justify-center items-center bg-quiz_blue self-stretch pt-3.5 pb-3.5 px-5 rounded-lg max-md:max-w-full">
            {quizName}
        </div>
    );
};

QuizHeader.propTypes = {
    quizName: PropTypes.string.isRequired
};

export default QuizHeader;

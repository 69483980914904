import { useState, useEffect } from 'react';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const useFetchQuestions = (quizId) => {
    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchQuestions = async () => {
            setIsLoading(true);
            try {
                const quizResponse = await axios.get(`${apiUrl}/api/quiz/${quizId}`);
                if (quizResponse.data && Array.isArray(quizResponse.data.question_ids)) {
                    const questionsData = await Promise.all(
                        quizResponse.data.question_ids.map(id =>
                            axios.get(`${apiUrl}/api/questions/${id}`)
                        )
                    );
                    const formattedQuestions = questionsData.map(response => response.data);
                    setQuestions(formattedQuestions);
                } else {
                    setError('Received unexpected data format from the API');
                }
            } catch (error) {
                setError('Error fetching questions: ' + error.message);
            }
            setIsLoading(false);
        };

        fetchQuestions();
    }, [quizId]);

    return { questions, isLoading, error };
};

export default useFetchQuestions;

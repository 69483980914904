import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const CategoryQuizCard = ({ quiz, imgSrcSmall, imgSrcMedium, imgSrcLarge }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateText = (text, length) => {
    if (text && text.length > length) {
      return text.substring(0, length) + "...";
    }
    return text;
  };

  // Function to choose which image source to use based on some condition, e.g., screen width
  const mediaBaseUrl = process.env.REACT_APP_API_URL;

  const getImageSrc = () => {
    const screenWidth = window.innerWidth;
    let selectedImgSrc = quiz.imgSrc; // Default image source

    if (screenWidth < 640) {
      selectedImgSrc = imgSrcSmall || quiz.imgSrc;
    } else if (screenWidth >= 640 && screenWidth < 1024) {
      selectedImgSrc = imgSrcMedium || quiz.imgSrc;
    } else {
      selectedImgSrc = imgSrcLarge || quiz.imgSrc;
    }

    // Prepend the base URL if the selected image source is a relative path
    return selectedImgSrc.startsWith('http') ? selectedImgSrc : `${mediaBaseUrl}${selectedImgSrc}`;
  };


  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden font-sans group flex flex-col justify-between h-full">
      <div className="w-full h-48">
        <img
          src={getImageSrc()} // Use the function to get the appropriate image source
          alt={quiz.name}
          className="object-cover w-full h-full"
        />
      </div>
      <div className="p-4 flex flex-col flex-grow">
        <h3 className="text-lg font-semibold text-gray-700">
          {quiz.name}
        </h3>
        <p className="text-sm text-gray-600 mt-1 flex-grow">
          {isExpanded ? quiz.description : truncateText(quiz.description, 100)}
        </p>
        {quiz.description && quiz.description.length > 100 && (
          <button
            onClick={toggleIsExpanded}
            className="text-quiz_blue text-xs mt-2 focus:outline-none"
          >
            {isExpanded ? 'Czytaj mniej' : 'Czytaj więcej'}
          </button>
        )}
      </div>
      <div className="p-4">
        <Link
          to={`/quiz/${quiz.slug}`}
          className="text-white bg-quiz_blue hover:bg-blue-700 font-bold rounded-md py-2 px-4 block text-center"
        >
          Rozpocznij quiz
        </Link>
      </div>
    </div>
  );
};

export default CategoryQuizCard;

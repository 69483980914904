import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const Tags = ({ onTagSelect }) => {
  const [tags, setTags] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/tags/`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data)
        setTags(data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    fetchTags();
  }, [apiUrl]);

  const handleTagSelect = (tagName) => {
    navigate(`/tag/${tagName}`);
    onTagSelect(tagName);
  };

  return (
    <div className="flex flex-wrap font-bold font-sans text-white justify-center items-center bg-blue-200 w-full p-4">
      {tags.map((tag) => (
        <div
          key={tag.id}
          onClick={() => handleTagSelect(tag.slug)}
          className="bg-quiz_blue m-1 px-3 py-1 rounded-full cursor-pointer"
        >
          {tag.name}
        </div>
      ))}
    </div>
  );
};

Tags.propTypes = {
  onTagSelect: PropTypes.func.isRequired,
};

export default Tags;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import FeaturedQuiz from './FeaturedQuiz';
import QuizList from './QuizList';

const apiUrl = process.env.REACT_APP_API_URL;

const LeftPane = () => {
  const { tagName } = useParams();
  const [quizzes, setQuizzes] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);

  // Definicja funkcji fetchQuizzes poza useEffect
  const fetchQuizzes = async (url = `${apiUrl}/api/quiz/`) => {
    if (tagName) {
      url += `?filter_type=tags&filter_field=name&filter_value=${encodeURIComponent(tagName)}`;
    }
    try {
      const response = await axios.get(url);
      if (response.data.results.length > 0) {
        setQuizzes(response.data.results);
        setIsEmpty(false);
        setNextUrl(response.data.next);
        setPreviousUrl(response.data.previous);
      } else {
        setQuizzes([]);
        setIsEmpty(true);
      }
    } catch (error) {
      console.error('Error fetching quizzes:', error);
      setIsEmpty(true);
    }
  };

  useEffect(() => {
    fetchQuizzes();  // Wywołanie funkcji wewnątrz useEffect
  }, [tagName]);

  const handlePageChange = (direction) => {
    const url = direction === 'next' ? nextUrl : previousUrl;
    if (url) {
      fetchQuizzes(url);
      window.scrollTo(0, 0);  // Przewijanie do góry przy każdej zmianie strony
    }
  };


  const featuredQuiz = quizzes.find(quiz => quiz.is_featured);
  const heading = tagName ? `#${tagName}` : 'NAJNOWSZE QUIZY';

  return (
    <div>
      {featuredQuiz && <FeaturedQuiz quiz={featuredQuiz} />}
      {isEmpty ? (
        <div className="text-center text-xl mt-5">Brak quizów dla tagu "{tagName}"</div>
      ) : (
        <QuizList
          quizzes={quizzes}
          heading={heading}
          nextUrl={nextUrl}
          previousUrl={previousUrl}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default LeftPane;

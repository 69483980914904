import React from 'react';
import { Link } from 'react-router-dom';
import QuizView from '../LeftPane/QuizView';

const RelatedQuizes = ({ quizzes }) => {
  return (
    <div className="bg-quiz_blue font-sans rounded-lg">
      <div className="text-sky-50 text-center text-3xl font-bold leading-10 whitespace-nowrap justify-center items-center bg-quiz_green self-stretch pt-3.5 pb-2 px-5 rounded-lg">
        PODOBNE QUIZY
      </div>
      <div className="m-3 pb-4">
        {quizzes.map((quiz) => (
          <Link to={`/quiz/${quiz.slug}`} key={quiz.slug} className="block mb-4">
            <QuizView {...quiz} />
          </Link>
        ))}
        <div className="flex justify-end">
          <Link to="/quizzes" className="text-white text-center text-lg not-italic font-medium leading-6 whitespace-nowrap bg-quiz_green flex w-[150px] px-1.5 py-1.5 rounded-md ">
            Zobacz więcej
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RelatedQuizes;

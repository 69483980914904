import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnswerButton from './AnswerButton';

const QuizQuestion = ({ question, selectedAnswers, toggleAnswer, submitted, correctAnswersMap }) => {
    const [showEnlargedImage, setShowEnlargedImage] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    // Dynamically choose the image source based on the screen width
    const getImageSrc = () => {
        const screenWidth = window.innerWidth;
        let selectedSrc = question.imgSrc; // Default image source

        if (screenWidth < 640) {
            selectedSrc = question.imgSrc_small_url || question.imgSrc;
        } else if (screenWidth >= 640 && screenWidth < 1024) {
            selectedSrc = question.imgSrc_medium_url || question.imgSrc;
        } else {
            selectedSrc = question.imgSrc_large_url || question.imgSrc;
        }

        // Prepend the base URL if the selected image source is a relative path
        return selectedSrc.startsWith('http') ? selectedSrc : `${apiUrl}${selectedSrc}`;
    };

    const handleImageClick = () => {
        setShowEnlargedImage(true);
    };

    const handleClose = () => {
        setShowEnlargedImage(false);
    };

    return (
        <div className="bg-gray-100 p-4 rounded-lg shadow-md flex flex-col items-center">
            {/* Enlarged image modal */}
            {showEnlargedImage && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50" onClick={handleClose}>
                    <img
                        src={getImageSrc()} // Dynamically set the image source
                        className="max-w-full max-h-full object-cover rounded-lg shadow-md cursor-pointer" // Style for the enlarged image
                        alt="Enlarged question"
                    />
                </div>
            )}

            <div className="bg-white p-4 rounded-lg shadow-md w-full mb-4 flex items-center">
                {/* Clickable image */}
                {question.imgSrc && (
                    <img
                        src={getImageSrc()} // Dynamically set the image source
                        className="mr-4 h-24 w-24 object-cover rounded-lg shadow-md cursor-pointer" // Added cursor-pointer
                        alt="Question"
                        onClick={handleImageClick}
                    />
                )}

                {/* Question text */}
                <h2 className="font-quicksand text-lg font-medium text-black flex-grow">
                    {question.text}
                </h2>
            </div>

            {/* Answer Buttons */}
            <div className="grid grid-cols-2 gap-4 w-full">
                {question.answers.map((answer) => (
                    <AnswerButton
                        key={answer.id}
                        answer={answer}
                        isSelected={selectedAnswers.includes(answer.id)}
                        onClick={() => toggleAnswer(answer.id)}
                        isSubmitted={submitted}
                        isCorrect={!!correctAnswersMap[answer.id]}
                    />
                ))}
            </div>
        </div>
    );
};

QuizQuestion.propTypes = {
    question: PropTypes.object.isRequired,
    selectedAnswers: PropTypes.array.isRequired,
    toggleAnswer: PropTypes.func.isRequired,
    submitted: PropTypes.bool.isRequired,
    correctAnswersMap: PropTypes.object.isRequired,
};

export default QuizQuestion;
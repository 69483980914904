import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import RelatedQuizes from './RelatedQuizes';  // Załóżmy, że jest to poprawna ścieżka importu

const apiUrl = process.env.REACT_APP_API_URL;

const QuizInfoPage = () => {
  const { quizName } = useParams();
  const navigate = useNavigate();
  const [quiz, setQuiz] = useState(null);
  const [relatedQuizzes, setRelatedQuizzes] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getImageSrc = (quiz) => {
    let selectedSrc = quiz.imgSrc;
    if (windowWidth < 640) {
      selectedSrc = quiz.imgSrc_small_url || quiz.imgSrc;
    } else if (windowWidth >= 640 && windowWidth < 1024) {
      selectedSrc = quiz.imgSrc_medium_url || quiz.imgSrc;
    } else {
      selectedSrc = quiz.imgSrc_large_url || quiz.imgSrc;
    }
    return selectedSrc.startsWith('http') ? selectedSrc : `${apiUrl}${selectedSrc}`;
  };

  useEffect(() => {
    const fetchQuizData = async () => {
      const encodedQuizName = encodeURIComponent(quizName);
      console.log(encodedQuizName)
      try {
        const response = await axios.get(`${apiUrl}/api/quiz/?slug=${encodedQuizName}`);
        if (response.data.results && response.data.results.length > 0) {
          const quizData = response.data.results[0]; // Uzyskanie pierwszego wyniku z tablicy 'results'

          setQuiz(quizData);

          const categoryID = quizData.category;
          const relatedResponse = await axios.get(`${apiUrl}/api/quiz/?filter_type=category&filter_field=id&filter_value=${categoryID}`);
          if (relatedResponse.data.results) {
            const filteredRelatedQuizzes = relatedResponse.data.results.filter(q => q.id !== quizData.id);
            setRelatedQuizzes(filteredRelatedQuizzes);

            console.log("Filtered Related Quizzes:", filteredRelatedQuizzes);
          }
        } else {
          console.error('Quiz not found with the given name:', quizName);
        }
      } catch (error) {
        console.error('Error fetching quiz data:', error);
      }
    };

    if (quizName) {
      fetchQuizData();
    }

  }, [quizName]);

  const handleStartQuiz = () => {
    navigate(`/quiz/start/${quiz.id}`, { state: { quizName: quiz.name, quizSlug: quiz.slug } });
  };

  if (!quiz) {
    return <div className="text-center p-4">Loading...</div>;
  }

  return (
    <>
      <div className="bg-gray-300 font-sans flex flex-col rounded-lg overflow-hidden">
        <div className="bg-quiz_blue p-4 flex items-center justify-between rounded-lg">
          <h1 className="text-white text-2xl font-bold">{quiz.name}</h1>
          <div className="text-white text-xl">
              {quiz.category_tree ? quiz.category_tree.join(' > ') : 'No categories'}
          </div>
        </div>
        <div className="flex h-full items-stretch">
          <div className="bg-white rounded-lg m-3 pb-4 flex-grow">
            <div className="flex">
              <div className="w-1/2 p-4">
                <img
                    loading="lazy"
                    src={getImageSrc(quiz)}
                    alt="Quiz"
                    className="w-full h-full object-cover rounded-lg"
                />
              </div>
              <div className="w-1/2 p-4 flex flex-col justify-between">
                <p className="text-black-700 mb-2 flex-grow">{quiz.description}</p>
                <div>
                  <div className="flex justify-between mb-2">
                    <p className="text-black-700">Liczba pytań: {quiz.number_of_questions}</p>
                    <p className="text-black-700">Trudność: {quiz.difficulty}</p>
                  </div>
                  <button
                      className="bg-quiz_green hover:bg-green-800 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out w-full"
                      onClick={handleStartQuiz}
                  >
                    ROZPOCZNIJ QUIZ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {relatedQuizzes && relatedQuizzes.length > 0 && (
        <div className="mt-3">
          <RelatedQuizes quizzes={relatedQuizzes}/>
        </div>
      )}
    </>
  );
};

export default QuizInfoPage;

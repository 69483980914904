import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;

const PopularCategories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/categories/?parent=null`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCategories(data.results);
      } catch (error) {
        console.error("Error fetching categories: ", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className=" mb-8 w-full bg-gray-300 rounded-lg font-sans">
      <div className="text-sky-50 text-center text-3xl  font-bold leading-10 whitespace-nowrap justify-center items-center bg-quiz_blue self-stretch pt-3.5 pb-2 px-5 rounded-lg max-md:max-w-full">
        Popularne Kategorie
      </div>
      <div className="mx-5">
      <ul className="w-full">
        {categories.map(category => (
          <li key={category.id} className="w-full flex flex-col p-2 rounded-lg hover:bg-blue-100">
            <Link to={`/kategorie/${category.name}`} className="text-neutral-500 text-2xl font-bold font-sans rounded border bg-white w-full pl-2.5 pr-5 py-4 border-solid border-blue-400 hover:border-blue-500 hover:bg-blue-50 hover:text-blue-600">
              {category.name}
            </Link>
          </li>
        ))}
      </ul>
      </div>
    </div>
  );
};

export default PopularCategories;
